.img-align {
    text-align: center !important;
}

.logo-dark-element {
    display: inline-block;
}

.footer-val {
    margin-top: 52% !important;
}