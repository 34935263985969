/* body {
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
    margin: 0;
    padding: 10px;
} */

.new-table-container {
    max-width: 1900px;
    max-height: 500px;
    /* Set a maximum height for vertical scrolling */
    /* margin: auto; */
    overflow-x: auto;
    overflow-y: auto !important;
    /* Enable vertical scrolling */
    /* Enable horizontal scrolling */
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background-color: white;
}

.new-table-container-table {
    width: 100%;
    /* min-width: 600px; */
    overflow-y: auto;
    /* Minimum width to maintain structure */
    border-collapse: collapse;
    /* Collapse borders */
}

.new-table-container-thead {
    background-color: #74e2;
    color: black;
}

.new-table-container-td {
    padding: 2px;
    text-align: center;
    border: 1px solid #d9dde5;
    /* Add borders */
}

.new-table-container-th {
    padding: 6px;
    text-align: center;
    border: 1px solid #d9dde5;
    /* Add borders */
}

.new-table-container-tr:hover {
    background-color: #f1f1f1;
}


.carousel-container {
    display: flex;
    justify-content: center;
    margin: 20px auto;
    /* Center the carousel */
}

.arrow {
    /* position: absolute; */
    position: fixed;
    /* Make arrows fixed */
    /* Position arrows absolutely within the card */
    top: 50%;
    /* Center vertically */
    transform: translateY(-50%);
    /* Adjust for centering */
    /* background: rgba(255, 255, 255, 0.8); */
    /* Semi-transparent background */
    border: none;
    font-size: 100px;
    /* Adjust size as needed */
    cursor: pointer;
    color: #007bff;
    /* Adjust color as needed */
    z-index: 10;
    /* Ensure buttons are above content */
    padding: 10px;
}

.arrow.disabled {
    /* color: #ccc; */
    /* Grey color for disabled state */
    cursor: not-allowed;
    /* Change cursor to indicate disabled */
    opacity: 0.6;
    /* Slightly transparent */
    pointer-events: none;
    /* Prevent click events */
}

.left-arrow {
    left: 92px;
    font-size: 100px;
    top: 50%;
    /* Center vertically */
    transform: translateY(-50%);
    /* Adjust for centering */
    /* Position on the left */
}

.right-arrow {
    right: 20px;
    font-size: 100px;
    top: 50%;
    /* Center vertically */
    transform: translateY(-50%);
    /* Adjust for centering */
    /* Position on the right */
}