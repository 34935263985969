@import url('https://fonts.googleapis.com/css2?family=Anek+Malayalam:wght@100;200;300;400;500;600;700;800&display=swap');

/* body {
  font-family: 'Anek Malayalam', sans-serif;
  background: linear-gradient(90.04deg, #556ee6 0.03%, #ffc0cb 99.96%);
  color: #11052c;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0 30px;
} */

.quiz-container {
  max-width: 700px;
  min-width: 250px;
  background: #ffffff;
  border-radius: 4px;
  /* margin-top: 100px; */
  /* padding: 30px 60px; */
}

.quiz-container .active-question-no {
  font-size: 28px;
  font-weight: 500;
  color: #556ee6;
}

.quiz-container .total-question {
  font-size: 16px;
  font-weight: 500;
  color: #e0dee3;
}

.quiz-container h2 {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}

.quiz-container ul {
  margin-top: 20px;
  margin-left: -40px;
}

.quiz-container ul li {
  text-decoration: none;
  list-style: none;
  color: #2d264b;
  font-size: 14px;
  background: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 16px;
  padding: 11px;
  margin-top: 15px;
  cursor: pointer;
}

.quiz-container ul .selected-answer {
  background: #556ee617;
  border: 1px solid #556ee6;
}

.quiz-container button {
  background:#556ee6;
  border-radius: 9px;
  font-size: 18px;
  color: #ffffff;
  padding: 10px 42px;
  outline: none;
  border: none;
  cursor: pointer;
  margin-top: 15px;
}

.quiz-container button:disabled {
  background: #e7e8e9;
  color: #9fa3a9;
  cursor: not-allowed;
}

.flex-right {
  display: flex;
  justify-content: flex-end;
}

.result h3 {
  font-size: 24px;
  letter-spacing: 1.4px;
  text-align: center;
}

.result p {
  font-size: 16px;
  font-weight: 500;
}

.result p span {
  color: #556ee6;
  font-size: 22px;
}

.qnoptiondata p {
 text-align: left !important;
  }
  p.qnoptiondata
  {
    text-align: left !important;
  }
  .spinner-border
  {
    z-index: 999 !important;
  }