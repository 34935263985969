.subject-list {
    background-color: #f8f9fa;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.custom-dropdown {
    z-index: 1050;
    /* Ensure this is higher than the surrounding elements */
    position: relative;
    /* Necessary for z-index to work */
}