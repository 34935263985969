.rightAlignText {
    text-align: right !important;
}

.card-bg {
    background-color: rgb(200, 227, 249) !important;
}

.button-align {
    margin-top: 15% !important;
    padding-left: 3% !important;
}

// .slider-container {
//     display: flex;
//     align-items: center;
//     margin-bottom: 10px;
//     /* Adjust spacing as needed */
// }

// .slider-label {
//     margin-left: 10px;
//     /* Space between slider and label */
// }

// .slider {
//     flex: 1;
//     /* Allows slider to take available space */
// }

.slider-container {
    // display: flex;
    /* Align children horizontally */
    align-items: center;
    /* Center items vertically */
    margin-bottom: 15px;
    /* Space between each slider container */
}

/* Container for the label to manage spacing */
.slider-label-container {
    // display: flex;
    align-items: center;
    /* Center the label vertically */
    margin-right: 10px;
    /* Add space between label and slider */
}

/* Slider styling */
.slider {
    flex-grow: 1;
    /* Allow the slider to take up available space */
}

/* Label styling */
.slider-label {
    font-size: 14px;
    /* Font size for the label */
    margin: 0;
    /* Remove default margins */
}

.text-right {
    text-align: right;
}

.percent {
    margin-left: 92% !important;
    margin-top: -20% !important;
    width: 20% !important
}

.badge-orange {
    background-color: orange !important;
    /* Use !important to override existing Bootstrap styles */
    color: white;
    /* Ensure text is readable */
}

.totalvaluesBadge {
    margin-left: 90% !important;
}