.cardfirstheight {
    height: 40% !important;
    margin-bottom: 0px !important;
}

.card-custom {
    border: 5px solid #d1d1d1;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
}

.card-custom-title {
    color: #d9534f;
    font-weight: bold;
}

.card-custom-text {
    margin-top: 10px;
    font-size: 14px;
    color: #333;
}