.switch-lg {
    min-height: 37px !important;
}
.textar{
    margin-left: 6% !important;
    width: 70%;
}


/* scrollbar
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {     
    background: rgba(255, 255, 255, 0.3);
} */
.tooltip-inner {
    max-width: 100% !important; /* Set a maximum width for the tooltip */
    white-space: pre-wrap; /* Handle long text by wrapping */
    overflow-wrap: break-word; /* Ensure long words break correctly */
    word-break: break-word; 
}

/*  */

.card-custom {
    border: 5px solid #d1d1d1;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
}

.card-custom-title {
    color: #d9534f; 
    font-weight: bold;
}

.card-custom-text {
    margin-top: 10px;
    font-size: 14px;
    color: #333;
}