.col-wd-10 {
  width: 10% !important;
}

.css-1nmdiq5-menu {
  background-color: #FFF !important //  z-index: 9999 !important;
}

// .crd-min-ht
// {
//   min-height: 110% !important;
//   max-height: 110% !important;
// }
.cls-qn-crd {
  margin-bottom: 4px !important;
}

.cls-qn-div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-align: left;
  height: 50px;
}

.cls-collapse-qn-active {
  // color: #34c38f;
  // background-color: rgba(52, 195, 143, 0.1);
  border-color: rgba(52, 195, 143, 0.1);
  ;
  transition: all 0.5s ease;
  padding: 8px;
}

.cls-collapse-qn-inactive {
  // color: #f46a6a;
  // background-color: rgba(52, 195, 143, 0.1);
  border-color: rgba(52, 195, 143, 0.1);
  ;
  transition: all 0.5s ease;
  padding: 8px;
}

.mr-8 {
  margin-right: 8px !important;
}

.table> :not(caption)>*>* {
  padding-top: 5px !important;
  padding-bottom: 5px !important
}

.form-select {
  width: 150px !important
}

input.switch+label:before {
  font-size: 8PX !important;
}

.txt-center {
  text-align: center;
}

.txt-right {
  text-align: right;
}

.pl-25 {
  padding-left: 25px !important;
}

input.switch+label {

  //line-height: 2;
  width: 60px !important;
  height: 24px !important;
}

.card-dashboard {
  padding: 2px !important;
}

.dashboard-me-6 {
  margin-right: 3.5rem !important;
}

.badge-orange {
  background-color: orange !important; /* Use !important to override existing Bootstrap styles */
  color: white; /* Ensure text is readable */
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}